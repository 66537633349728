import React, { useEffect, useState } from 'react';
import { Range, getTrackBackground } from 'react-range';
import { FaPlay, FaPause,FaRedo, FaDownload } from 'react-icons/fa'; 
import axios from '../../axiosConfig';
import './VideoPlayer.css';
import {  IoCloseCircleOutline  } from 'react-icons/io5'; // Import icon

const VideoPlayer = ({ videoId, videoUrl, videoRef, videoFileKey, cancelCurrent , setIsPopupOpen, thumbsrc, isNative }) => {
  const STEP = 0.1;
  const MIN = 0;
  const MAX = 100;
  const [values, setValues] = useState([MIN, MAX]);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);    
  const [trimStart, setTrimStart] = useState(0);
  const [trimEnd, setTrimEnd] = useState(0);

  // Função para formatar o tempo no estilo MM:SS
 const formatTime = (seconds) => {
  // Garante que não existam valores negativos
  seconds = Math.max(0, seconds);

  const minutes = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
};


  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      setCurrentTime(0);
      setIsPlaying(false); 
      setTrimStart(0);
      setTrimEnd(0);
    }
  }, [videoRef, videoUrl]);

  useEffect(() => {       
    if (!videoRef?.current) return;

    const handleLoadedMetadata = () => {
      if (!videoRef.current) return;
      setDuration(videoRef.current.duration);
      setValues([0, 100]);     
    };

    const handleTimeUpdate = () => {
      if (!videoRef.current) return;
      setCurrentTime(videoRef.current.currentTime);
      
      if (trimEnd > 0 && videoRef.current.currentTime >= trimEnd) {
        videoRef.current.pause();
        setIsPlaying(false);
        setCurrentTime(trimEnd);
      }
    };

    videoRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);
    videoRef.current.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('loadedmetadata', handleLoadedMetadata);
        videoRef.current.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, [videoRef, duration, isPlaying, trimStart, trimEnd]);

  const handleRangeChange = (newValues) => {
    if (newValues[0] < newValues[1]) {
      setValues(newValues);
      setTrimStart((newValues[0] / 100) * duration);
      setTrimEnd((newValues[1] / 100) * duration);
    }

    if (!isPlaying && videoRef?.current) {
      const newTime = (newValues[0] / 100) * duration;
      videoRef.current.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const handleThumbDragEnd = () => {
    if (videoRef?.current) {
      const newStartTime = (values[0] / 100) * duration;
      videoRef.current.currentTime = newStartTime;
      setCurrentTime(newStartTime);
    }
  };

  const handleSeek = (clickPosition) => {
    if (videoRef?.current) {
      if (clickPosition < trimStart) {
		  console.log("sancho1")
        videoRef.current.currentTime = trimStart;
        setCurrentTime(trimStart);
      } else if (trimEnd > 0 && (clickPosition > trimEnd)) {
		  console.log("sancho2")
        videoRef.current.currentTime = trimEnd;
        setCurrentTime(trimEnd);
      } else {
		  console.log("sancho3")
        videoRef.current.currentTime = clickPosition;
        setCurrentTime(clickPosition);
      }
    }
  };

  const handleTrackClick = (e) => {
    if (duration > 0 && videoRef?.current) {		
      const rect = e.target.getBoundingClientRect();
      const clickPosition = ((e.clientX - rect.left) / rect.width) * duration;
	  
      handleSeek(clickPosition);
    }
  };

  const handlePlayPause = () => {
	  console.log("aqui001")
    if (videoRef?.current) {
		console.log("aqui002")
      if (isPlaying) {
		  console.log("aqui Is Playing")
		   console.log({currentTime,trimEnd,trimStart})
        videoRef.current.pause();
      } else {
		  console.log("aqui Is Paused")
		  console.log({currentTime,trimEnd,trimStart})
        if (((trimEnd > 0) && currentTime >= trimEnd) || currentTime < trimStart) {
          videoRef.current.currentTime = trimStart;
        }
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleReplay = () => {	            
    if (videoRef?.current) {
      videoRef.current.currentTime = trimStart;
      if (!isPlaying) {
        videoRef.current.play();
      }
      setIsPlaying(true);		 
    }
  };

  const handleDownload = async () => {
    setIsDownloading(true);

    try {
      if (values[0] === MIN && values[1] === MAX) {
        const link = document.createElement('a');
        link.href = videoUrl;
        link.download = 'video.mp4';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        const requestData = {
          videoUrl: videoFileKey,
          startTime: trimStart,
          endTime: trimEnd,
        };
        const response = await axios.post(`/VideoServe/process-video`, requestData, {
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const blob = await response.data;
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'trimmed_video.mp4';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error('Error processing video:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  const coloraccent = getComputedStyle(document.documentElement).getPropertyValue('--color-accent').trim();

  // Determina a duração para exibição: trimada ou total
  const displayDuration = trimEnd > 0 ? trimEnd - trimStart : duration;
  const displayCurrentTime = trimEnd > 0 ? currentTime - trimStart : currentTime;

  return (
    <div className="video-container">      	
      <button className="close-button-p" onClick={() => setIsPopupOpen(false)}>
	    <IoCloseCircleOutline size={40} />
	  </button>	
      <div
        style={{
          width: '100%',		  
          height: '0',
          paddingBottom: '56.25%', // Aspect ratio 16:9
          position: 'relative',
          cursor: 'pointer',
          backgroundColor: '#000',
		  borderRadius: '10px'
        }}
      >                
        <video
          key={videoId}
          ref={videoRef}
          src={videoUrl}          
		  preload='auto'
		  poster={thumbsrc}
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '0',
            left: '0',
            display: 'block',			
			borderRadius: '10px'
			
          }}
          controls={false}
          playsInline={true}
        />
      </div>
	   	  

      <div className={`video-info ${isNative ? ' video-info-native' : ' video-info-nonnative'}`}>            
      <Range
        values={values}
        step={STEP}
        min={MIN}
        max={MAX}
        onChange={handleRangeChange}
        onFinalChange={handleThumbDragEnd}
        renderTrack={({ props, children }) => (
          <div					    
            role="track"
            ref={props.ref}
            style={{
              height: '30px',
              display: 'flex',			  			  
              width: '100%',
              background: getTrackBackground({
                values,
                colors: ['#ccc', coloraccent, '#ccc'],
                min: MIN,
                max: MAX,
              }),
              alignSelf: 'center',
              position: 'relative',
            }}
            onClick={handleTrackClick}
          >
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: `${(currentTime / duration) * 100}%`,
                height: '100%',
                width: '2px',
                backgroundColor: 'yellow',
              }}
            />
            {children}
          </div>
        )}
        renderThumb={({ index, props }) => (
          <div
            key={index}
            role="slider"
            aria-valuemin={MIN}
            aria-valuemax={MAX}
            aria-valuenow={values[index]}
            {...props}
            style={{
              ...props.style,
              height: '24px',
              width: '24px',
              borderRadius: '50%',
              backgroundColor: coloraccent,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 2px 6px #AAA',
              zIndex: 2,
            }}
          >
            <div
              style={{
                height: '16px',
                width: '5px',
                backgroundColor: 'white',
              }}
            />
          </div>
        )}
      />
          </div>
      <div className="video-controls">
		<div className="controls-left">
			<button onClick={handlePlayPause}>{isPlaying ? <FaPause /> : <FaPlay />}</button>
			<button onClick={handleReplay}><FaRedo /></button>			
		</div>
	   <div className="controls-right">
	   
    <span>{formatTime(displayCurrentTime)} / {formatTime(displayDuration)}</span>
	<button onClick={handleDownload}><FaDownload /></button>
  </div>
</div>

      
      {isDownloading && (
        <div className="downloading-popup">
          <p>Processing your video, please wait...</p>
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
