import React from 'react';
import About from './About';
import Contact from './Contact';

const CombinedPage = () => {
  return (
    <div>
      {/* Adiciona IDs para facilitar a rolagem */}
      <div id="about">
        <About />
      </div>
      <div id="contact">
        <Contact />
      </div>
    </div>
  );
};

export default CombinedPage;
