import React, { useState } from 'react';
import axios from '../axiosConfig';
import { useNavigate } from 'react-router-dom';
import './ForgotPassword.css';

function ForgotPassword() {
  const [formData, setFormData] = useState({ email: '' });
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Include the host in the request data
      const requestData = {
        email: formData.email,
        host: window.location.origin, // This will be the URL like "https://yourapp.com"
      };

      const response = await axios.post('/account/forgotpassword', requestData);
      const result = response.data;

      if (result.success) {
        setMessage(result.messagetoshow);
        // Optionally navigate to another page or just show the message
        // navigate('/login', { state: { message: result.messagetoshow } });
      } else {
        const errorMessage = Array.isArray(result.messagetoshow)
          ? result.messagetoshow.join('\n')
          : result.messagetoshow;

        setMessage(errorMessage);
      }
    } catch (error) {
      let errorMessage = 'Please try again.';
      if (error.response) {
        if (Array.isArray(error.response.data?.messagetoshow)) {
          errorMessage = error.response.data.messagetoshow.join('\n');
        } else if (error.response.data?.messagetoshow) {
          errorMessage = error.response.data.messagetoshow;
        } else if (error.response.data.errors) {
          errorMessage = Object.values(error.response.data.errors).flat().join('; ');
        } else if (error.response.data.title) {
          errorMessage = error.response.data.title;
        }
      }

      setMessage('Error: ' + errorMessage);
    }
  };

  return (
    <div className="forgotpassword-container">
      <form onSubmit={handleSubmit} className="forgotpassword-form">
        <div className="forgotpassword-input-group">
          <label>Your email address:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="forgotpassword-button">Reset Password</button>
      </form>

      {message && <p className="forgotpassword-message">{message}</p>}

      <div className="forgotpassword-footer">
        <span>Remembered your password? <a href="/login" className="login-link">Log in</a></span>
      </div>
    </div>
  );
}

export default ForgotPassword;
