import React, { useEffect, useRef } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import About from './About';
import ProfilePage from './Profile';
import Contact from './Contact';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import Register from './Register';
import CombinedPage from './CombinedPage';
import VideosPage from './videospage/VideosPage';
import ProtectedRoute from './ProtectedRoute';
import SportCenterAdminPage from './admin/SportCenterAdminPage';
import StatusPage from './admin/StatusPage';
import { toast, ToastContainer } from 'react-toastify';
import PullToRefresh from 'react-pull-to-refresh';
import 'react-toastify/dist/ReactToastify.css';
import { Capacitor } from '@capacitor/core';

const detectNative = () => {
	
	if (Capacitor.isNativePlatform()) {
	   console.log("********** isNative")
       return true;
     }	 
	 return false;
}

const detectIsMobile = (isItNative) => {
	
	if (isItNative) {return false}; //native is not mobile browser,
	 
 	const userAgent = window.navigator.userAgent.toLowerCase();
	console.log('************ userAgent:', userAgent);
    return /android|iphone|ipad|ipod/.test(userAgent);
	
	 
};

const isSmallMenu = (isItNative,isItMobile) => 
{   		
    console.log('************ TisSmallMenu:', isItNative);
	if (isItNative ||  isItMobile ) 
	{
		document.documentElement.style.setProperty('--footer-height', '40px');
		document.documentElement.style.setProperty('--header-padding', '20px');	
		return true;
	}
	else 
	{
		document.documentElement.style.setProperty('--footer-height', '100px');
		document.documentElement.style.setProperty('--header-padding', '0px');
		return false;
	}
	 
}



const AppWrapper = ({ isAuthenticated, handleLogin, handleLogout, user }) => {
  const location = useLocation();
  console.log("sanchesFirst",location)
  const mainContentRef = useRef(null);
  
  const isNative = detectNative();
  const isMobile = detectIsMobile(isNative)
  console.log("********* isNative,isMobile",isNative,isMobile)
  
  const smallMenu = isSmallMenu(isNative,isMobile);
  
  console.log("********* is Small Menu",smallMenu)

  return (
    <div className="app-wrapper">
      <Header isAuthenticated={isAuthenticated} onLogout={handleLogout} user={user} smallMenu = {smallMenu} />
        <div className="App-main">      
        <main ref={mainContentRef} >
          <ToastContainer
            position="top-center"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />

          <Routes>
            <Route path="/" element={<VideosPage isNative={isNative} isMobile={isMobile}/>} />
            <Route path="/login" element={<Login onLogin={handleLogin} />} />
            <Route path="/profile" element={<ProfilePage user={user} onLogout={handleLogout} />} />
            <Route path="/register" element={<Register onLogin={handleLogin} />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/about-contact" element={<CombinedPage />} />

            {isAuthenticated && (
              <>
                <Route path="/admin/SportCenterAdminPage" element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <SportCenterAdminPage />
                  </ProtectedRoute>
                } />
                <Route path="/admin/StatusPage" element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <StatusPage  isNative={isNative} isMobile={isMobile}/>
                  </ProtectedRoute>
                } />
              </>
            )}
          </Routes>
        </main>
      </div>

      <Footer isAuthenticated={isAuthenticated}  smallMenu = {smallMenu}  user={user}  />
    </div>
  );
};

export default AppWrapper;
