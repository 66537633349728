import React from 'react';
import noImage from '../../images/selectSC.png';
import AddSportCenterList from './AddSportCenterList';

const SportCentersList = ({ sportCenters, onAdd, onRemove, onSelect }) => {
  const handleAddSportCenter = (newSportCenter) => {
    console.log("Selecionou:");
    console.log({ newSportCenter });
    onSelect(newSportCenter);
    sessionStorage.setItem('selectedSportCenter', JSON.stringify(newSportCenter));
  };

  return (
    <div className="sport-centers-master-div">
      <div>
        <h1>Select a Sport Center</h1>
        <AddSportCenterList onSportCenterSelect={handleAddSportCenter} />
      </div>
      
	  
	  {false && 
	  (
         <h2>Your favorite Sport Centers!</h2>
	  )}
	  
	   {false && 
	  (
      <div className="sport-centers-scrollable">
        <div className="sport-centers-grid">
          {sportCenters.map((center, index) => (
            <div key={index} className="sport-center-container">
              <div className="sportcenternamediv">
                <h2>{center?.name}</h2>
              </div>
              <div
                className="sport-center-box"
                style={{ backgroundImage: `url(${center ? center.picture : noImage})` }}
                onClick={() => center && onSelect(center)}
              ></div>

              {center && (
                <button
                  className="remove-button"
                  onClick={() => onRemove(center, index)}
                >
                  🗑 Remove
                </button>
              )}
              {!center && (
                <button
                  className="add-button2"
                  onClick={(e) => {
                    e.stopPropagation();
                    onAdd();
                  }}
                >
                  ✛ Add
                </button>
              )}
            </div>
          ))}
	  </div>
      </div>
	  )}
	  
	  </div>
	   
  );
};

export default SportCentersList;
