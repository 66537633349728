import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <section id="contact" className="section">
      <h1>Contact Us</h1>
      <p>Formas de entrar em contato...</p>
	  
	       <h1>Contact Us</h1>
      <p>Formas de entrar em contato...</p>
	  
	      <h1>Contact Us</h1>
      <p>Formas de entrar em contato...</p>
	  
	       <h1>Contact Us</h1>
      <p>Formas de entrar em contato...</p>
	  
	   <h1>Contact Us</h1>
      <p>Formas de entrar em contato...</p>
	  
	       <h1>Contact Us</h1>
      <p>Formas de entrar em contato...</p>
	  
	      <h1>Contact Us</h1>
      <p>Formas de entrar em contato...</p>
	  
	       <h1>Contact Us</h1>
      <p>Formas de entrar em contato...</p>
	  
	   <h1>Contact Us</h1>
      <p>Formas de entrar em contato...</p>
	  
	       <h1>Contact Us</h1>
      <p>Formas de entrar em contato...</p>
	  
	      <h1>Contact Us</h1>
      <p>Formas de entrar em contato...</p>
	  
	       <h1>Contact Us</h1>
      <p>Formas de entrar em contato...</p>
	  
	   <h1>Contact Us</h1>
      <p>Formas de entrar em contato...</p>
	  
	       <h1>Contact Us</h1>
      <p>Formas de entrar em contato...</p>
	  
	      <h1>Contact Us</h1>
      <p>Formas de entrar em contato...</p>
	  
	       <h1>Contact Us</h1>
      <p>Formas de entrar em contato...</p>
    </section>
  );
};

export default Contact;
