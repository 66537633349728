import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import {  AiOutlineVideoCamera, AiTwotoneVideoCamera,
          AiOutlineInfoCircle,  AiTwotoneInfoCircle,
		  AiOutlineBell} from 'react-icons/ai'; // Import icons

//import {  BiUserCircle } from 'react-icons/bi'; // Import icons		  
import {  IoPerson,IoPersonOutline,
          IoConstruct, IoConstructOutline,
          IoBuildOutline ,IoAnalytics  } from 'react-icons/io5'; // Import icons		  

const Footer = ({ platform, isAuthenticated, smallMenu,user }) => {
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [activeTab, setActiveTab] = useState("/");

  const handleTabClick = (path) => {
    setActiveTab(path);
  };

  const toggleSubmenu = () => {
    handleTabClick("/config");
    setShowSubmenu(!showSubmenu);
  };

  if (smallMenu) {
    return (
      <nav className="tab-bar">
        {/* Ícone de Vídeos */}
        <Link to="/" className="tab-bar-item" onClick={() => handleTabClick("/")}>
          {activeTab === "/" ? <AiTwotoneVideoCamera size={24} /> : <AiOutlineVideoCamera size={24} />}
          <span>Videos</span>
        </Link>
        
        {/* Ícone de Info */}
        <Link to="/about-contact" className="tab-bar-item" onClick={() => handleTabClick("/about-contact")}>
          {activeTab === "/about-contact" ? <AiTwotoneInfoCircle size={24} /> : <AiOutlineInfoCircle size={24} />}
          <span>About</span>
        </Link>
        
        {/* Ícone de Perfil */}
        <Link to={isAuthenticated ? "/profile" : "/login"} className="tab-bar-item" onClick={() => handleTabClick("/profileLogin")}>
          {activeTab === "/profileLogin" ? <IoPerson size={24} /> : <IoPersonOutline size={24} />}
          <span>{isAuthenticated ? "Profile" : "Login"}</span>
        </Link>

        {/* Ícone de Configurações */}
        {isAuthenticated && user.isAdmin && (
          <div className="tab-bar-item" onClick={toggleSubmenu} style={{ position: 'relative' }}>
            {activeTab === "/config" ? <IoConstruct size={24} /> : <IoConstructOutline size={24} />}
            <span>Config</span>

            {showSubmenu && (
              <div className="submenu">
                <Link to="/admin/SportCenterAdminPage" className="submenu-item">
                                <IoBuildOutline size={20} />
                  <span>Crud Admin</span>
                </Link>

                <Link to="/admin/StatusPage" className="submenu-item">
                                <IoAnalytics size={20} />
                  <span>Status</span>
                </Link>
              </div>
            )}
          </div>
        )}
      </nav>
    );
	
	
  } else {
    // Footer for desktop
    return (
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-logo">
            <img src={require('../images/logoGold.png')} alt="Logo" />
          </div>
          <div className="footer-copyright">
            <p>&copy; {new Date().getFullYear()} Sports Playback. Todos os direitos reservados.</p>
          </div>
          <div className="footer-social">
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a>
          </div>
        </div>
      </footer>
    );
  }
};


export default Footer;
