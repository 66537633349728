import React from 'react';
import './About.css';

const About = () => {
  return (
    <section id="about" className="section">
      <h1>About Us</h1>
      <p>Lorem ipsum dolor sit amet...</p>
      <img src="https://via.placeholder.com/600x300" alt="About us" className="about-image" />
      <p>Duis aute irure dolor in reprehenderit...</p>
	  
	  We Care About Your PrivacyWe and our 811 partners store and access personal data, like browsing data or unique identifiers, on your device. Selecting "I Accept" enables tracking technologies to support the purposes shown under "we and our partners process data to provide," whereas selecting "Reject All" or withdrawing your consent will disable them. If trackers are disabled, some content and ads you see may not be as relevant to you. You can resurface this menu to change your choices or withdraw consent at any time by clicking the ["privacy preferences"] link on the bottom of the webpage [or the floating icon on the bottom-left of the webpage, if applicable]. Your choices will have effect within our Website. For more details, refer to our Privacy Policy.
We and our partners process data to provide:
Use precise geolocation data. Actively scan device characteristics for identification. Store and/or access information on a device. Personalised advertising and content, advertising and content measurement, audience research and services development.List of Partners (vendors)

We Care About Your PrivacyWe and our 811 partners store and access personal data, like browsing data or unique identifiers, on your device. Selecting "I Accept" enables tracking technologies to support the purposes shown under "we and our partners process data to provide," whereas selecting "Reject All" or withdrawing your consent will disable them. If trackers are disabled, some content and ads you see may not be as relevant to you. You can resurface this menu to change your choices or withdraw consent at any time by clicking the ["privacy preferences"] link on the bottom of the webpage [or the floating icon on the bottom-left of the webpage, if applicable]. Your choices will have effect within our Website. For more details, refer to our Privacy Policy.
We and our partners process data to provide:
Use precise geolocation data. Actively scan device characteristics for identification. Store and/or access information on a device. Personalised advertising and content, advertising and content measurement, audience research and services development.List of Partners (vendors)

      <p>Duis aute irure dolor in reprehenderit...</p>
	        <p>Duis aute irure dolor in reprehenderit...</p>
			      <p>Duis aute irure dolor in reprehenderit...</p>
				        <p>Duis aute irure dolor in reprehenderit...</p>
						      <p>Duis aute irure dolor in reprehenderit...</p>
							  
							       <p>Duis aute irure dolor in reprehenderit...</p>
	  
	  We Care About Your PrivacyWe and our 811 partners store and access personal data, like browsing data or unique identifiers, on your device. Selecting "I Accept" enables tracking technologies to support the purposes shown under "we and our partners process data to provide," whereas selecting "Reject All" or withdrawing your consent will disable them. If trackers are disabled, some content and ads you see may not be as relevant to you. You can resurface this menu to change your choices or withdraw consent at any time by clicking the ["privacy preferences"] link on the bottom of the webpage [or the floating icon on the bottom-left of the webpage, if applicable]. Your choices will have effect within our Website. For more details, refer to our Privacy Policy.
We and our partners process data to provide:
Use precise geolocation data. Actively scan device characteristics for identification. Store and/or access information on a device. Personalised advertising and content, advertising and content measurement, audience research and services development.List of Partners (vendors)

We Care About Your PrivacyWe and our 811 partners store and access personal data, like browsing data or unique identifiers, on your device. Selecting "I Accept" enables tracking technologies to support the purposes shown under "we and our partners process data to provide," whereas selecting "Reject All" or withdrawing your consent will disable them. If trackers are disabled, some content and ads you see may not be as relevant to you. You can resurface this menu to change your choices or withdraw consent at any time by clicking the ["privacy preferences"] link on the bottom of the webpage [or the floating icon on the bottom-left of the webpage, if applicable]. Your choices will have effect within our Website. For more details, refer to our Privacy Policy.
We and our partners process data to provide:
Use precise geolocation data. Actively scan device characteristics for identification. Store and/or access information on a device. Personalised advertising and content, advertising and content measurement, audience research and services development.List of Partners (vendors)

      <p>Duis aute irure dolor in reprehenderit...</p>
	        <p>Duis aute irure dolor in reprehenderit...</p>
			      <p>Duis aute irure dolor in reprehenderit...</p>
				        <p>Duis aute irure dolor in reprehenderit...</p>
						      <p>Duis aute irure dolor in reprehenderit...</p>
							  
							       <p>Duis aute irure dolor in reprehenderit...</p>
	  
	  We Care About Your PrivacyWe and our 811 partners store and access personal data, like browsing data or unique identifiers, on your device. Selecting "I Accept" enables tracking technologies to support the purposes shown under "we and our partners process data to provide," whereas selecting "Reject All" or withdrawing your consent will disable them. If trackers are disabled, some content and ads you see may not be as relevant to you. You can resurface this menu to change your choices or withdraw consent at any time by clicking the ["privacy preferences"] link on the bottom of the webpage [or the floating icon on the bottom-left of the webpage, if applicable]. Your choices will have effect within our Website. For more details, refer to our Privacy Policy.
We and our partners process data to provide:
Use precise geolocation data. Actively scan device characteristics for identification. Store and/or access information on a device. Personalised advertising and content, advertising and content measurement, audience research and services development.List of Partners (vendors)

We Care About Your PrivacyWe and our 811 partners store and access personal data, like browsing data or unique identifiers, on your device. Selecting "I Accept" enables tracking technologies to support the purposes shown under "we and our partners process data to provide," whereas selecting "Reject All" or withdrawing your consent will disable them. If trackers are disabled, some content and ads you see may not be as relevant to you. You can resurface this menu to change your choices or withdraw consent at any time by clicking the ["privacy preferences"] link on the bottom of the webpage [or the floating icon on the bottom-left of the webpage, if applicable]. Your choices will have effect within our Website. For more details, refer to our Privacy Policy.
We and our partners process data to provide:
Use precise geolocation data. Actively scan device characteristics for identification. Store and/or access information on a device. Personalised advertising and content, advertising and content measurement, audience research and services development.List of Partners (vendors)

      <p>Duis aute irure dolor in reprehenderit...</p>
	        <p>Duis aute irure dolor in reprehenderit...</p>
			      <p>Duis aute irure dolor in reprehenderit...</p>
				        <p>Duis aute irure dolor in reprehenderit...</p>
						      <p>Duis aute irure dolor in reprehenderit...</p>
							  
							       <p>Duis aute irure dolor in reprehenderit...</p>
	  
	  We Care About Your PrivacyWe and our 811 partners store and access personal data, like browsing data or unique identifiers, on your device. Selecting "I Accept" enables tracking technologies to support the purposes shown under "we and our partners process data to provide," whereas selecting "Reject All" or withdrawing your consent will disable them. If trackers are disabled, some content and ads you see may not be as relevant to you. You can resurface this menu to change your choices or withdraw consent at any time by clicking the ["privacy preferences"] link on the bottom of the webpage [or the floating icon on the bottom-left of the webpage, if applicable]. Your choices will have effect within our Website. For more details, refer to our Privacy Policy.
We and our partners process data to provide:
Use precise geolocation data. Actively scan device characteristics for identification. Store and/or access information on a device. Personalised advertising and content, advertising and content measurement, audience research and services development.List of Partners (vendors)

We Care About Your PrivacyWe and our 811 partners store and access personal data, like browsing data or unique identifiers, on your device. Selecting "I Accept" enables tracking technologies to support the purposes shown under "we and our partners process data to provide," whereas selecting "Reject All" or withdrawing your consent will disable them. If trackers are disabled, some content and ads you see may not be as relevant to you. You can resurface this menu to change your choices or withdraw consent at any time by clicking the ["privacy preferences"] link on the bottom of the webpage [or the floating icon on the bottom-left of the webpage, if applicable]. Your choices will have effect within our Website. For more details, refer to our Privacy Policy.
We and our partners process data to provide:
Use precise geolocation data. Actively scan device characteristics for identification. Store and/or access information on a device. Personalised advertising and content, advertising and content measurement, audience research and services development.List of Partners (vendors)

      <p>Duis aute irure dolor in reprehenderit...</p>
	        <p>Duis aute irure dolor in reprehenderit...</p>
			      <p>Duis aute irure dolor in reprehenderit...</p>
				        <p>Duis aute irure dolor in reprehenderit...</p>
						      <p>Duis aute irure dolor in reprehenderit...</p>
    </section>
  );
};

export default About;
