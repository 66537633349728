import React, { useState, useEffect, useRef } from 'react';
import axios from '../../axiosConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import {  AiOutlineClockCircle  } from 'react-icons/ai'; // Import icon


// Estilos do Swiper
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';

import SelectCourt from './SelectCourt';
import CourtDetailsPage from './CourtDetailsPage';
import DateDetailsPage from './DateDetailsPage';
import AddSportCenterPopup from './AddSportCenterPopup';
import SportCentersList from './SportCentersList';
import SelectedDetails from './SelectedDetails';
import VideoPlayer from './VideoPlayer';
import './VideosPage.css';
import ImageNoVideo from '../../images/imagenovideo.svg';
import ImageThumb from '../../images/videothumbpng.png';

const swiperSettings = {
  spaceBetween: 10,
  slidesPerView: 2,
  observer: true,
  observeParents: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    dynamicBullets: true,
  },
};

const VideosPage = ({isNative,isMobile}) => { 
  const [sportCenters, setSportCenters] = useState(new Array(4).fill(null));
  const [selectedSportCenter, setSelectedSportCenter] = useState(() => {
    const savedSportCenter = sessionStorage.getItem('selectedSportCenter');
    return savedSportCenter ? JSON.parse(savedSportCenter) : null;
  });
  const [selectedCourt, setSelectedCourt] = useState(() => {
    const savedCourt = sessionStorage.getItem('selectedCourt');
    return savedCourt ? JSON.parse(savedCourt) : null;
  });
  const [selectedDate, setSelectedDate] = useState(() => {
    const savedDate = sessionStorage.getItem('selectedDate');
    return savedDate ? new Date(JSON.parse(savedDate)) : null;
  });
  const [selectedHour, setSelectedHour] = useState(() => {
    const savedHour = sessionStorage.getItem('selectedHour');
    return savedHour ? JSON.parse(savedHour) : null;
  });
  const [step, setStep] = useState(() => {
    const savedStep = sessionStorage.getItem('step');
    return savedStep ? JSON.parse(savedStep) : 0;
  });    

  const [isLoaded, setIsLoaded] = useState(false);
  const [sections, setSections] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);
  const fetchingThumbnails = useRef(new Set());
  const videoRef = useRef(null);
  const initialLoadRef = useRef(true);
  const [swiperMounted, setSwiperMounted] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false); // Estado para abrir o popup

  const [updateKey, setUpdateKey] = useState(generateUniqueKey());
  const mainContentRef = useRef(null);  // Refer�ncia para o container principal

  
  const [canRefresh, setcanRefresh] = useState(false);  
  const [isRefreshing, setIsRefreshing] = useState(false);  // Estado de refresh
  const startY = useRef(0);  // Guarda o ponto inicial do toque
  const pullDistance = useRef(0);  // Armazena a dist�ncia puxada

  const [pullingDistance, setPullingDistance] = useState(0);  // Estado para a anima��o de puxar
 
  useEffect(()=> {
	  console.log("popup open or closed")	  	  	  
	  
	  const canRef = isNative && !isPopupOpen;
	  
	  console.log("Setando Refresh para ",{canRef})
	  
	  setcanRefresh(canRef);	  
  },[isNative,isPopupOpen]);
  
  useEffect(() => {
    setSwiperMounted(true);
  }, []); // Only run once after component mounts

  useEffect(() => {
    sessionStorage.setItem('step', JSON.stringify(step));
  }, [step]);

  useEffect(() => {
    if (initialLoadRef.current) {
      initialLoadRef.current = false;
      if (selectedHour) {
        handleHourSelect(selectedHour);
      }
    }
  }, [selectedHour]);

  useEffect(() => {
    if (!selectedSportCenter || !selectedCourt || !selectedDate || !selectedHour) {
      setCurrentVideo(null);
    }
  }, [selectedSportCenter, selectedCourt, selectedDate, selectedHour]);

  useEffect(() => {
    if (!sections || sections.length === 0) {
      fetchingThumbnails.current.clear();
    }
  }, [sections]);
 
  // Fun��o de refresh
  const handleRefresh = async () => {
	
	if (!canRefresh){
		  console.log("RegreshDesabilitado")
		  return
      }
	  
	  
	  console.log("entrou no handleRefresh")
    if (isRefreshing) return;  // Evitar m�ltiplos refresh ao mesmo tempo

    setPullingDistance(100 * 0.5);  // Volta a posi��o original ap�s o refresh ser conclu�do
	setIsRefreshing(true);  // Ativa o estado de refresh
	
    console.log("Atualizando a pagina..." , {step});

    await handleUpdate();  // Aguarda o update

	setTimeout(() => {
		setPullingDistance(0);  // Volta a posi��o original ap�s o refresh ser conclu�do
		setIsRefreshing(false);  // Conclui o refresh
	}, 500); 
    
 
  };

  useEffect(() => {
	  
    const mainContent = mainContentRef.current;

    // Fun��o para detectar quando o toque come�a
    const handleTouchStart = (e) => {
	   if (!canRefresh){
		   console.log("RegreshDesabilitado")
		   return
       }	
		
		
	  console.log("handleTouchStart")	
      if (mainContent.scrollTop === 0) {
		  console.log("handleTouchStart -> scroltop0")
        startY.current = e.touches[0].clientY;  // Captura a posi��o inicial do toque
        pullDistance.current = 0;  // Reseta a dist�ncia puxada
        setPullingDistance(0);  // Reseta a dist�ncia visual
      }
    };

    // Fun��o para detectar quando o usu�rio est� "puxando" para baixo
    const handleTouchMove = (e) => {
		
	  if (!canRefresh){
		  console.log("RegreshDesabilitado")
		  return
      }
	  
	  console.log("move", {canRefresh})
	  
      const currentY = e.touches[0].clientY;
      pullDistance.current = currentY - startY.current;  // Calcula a dist�ncia puxada
	  
	  if (pullDistance.current > 200) {
         pullDistance.current = 200;
      }
	  
      if (pullDistance.current > 0 && mainContent.scrollTop === 0) {		
        setPullingDistance(pullDistance.current * 0.5);  // Aplica um fator de resist�ncia visual
      }
    };

    // Fun��o para detectar quando o toque � solto
    const handleTouchEnd = () => {

	  if (!canRefresh){
		  console.log("RegreshDesabilitado")
		  return () => {
	  console.log("RemoveOsHandlers" , {canRefresh})
      // Remove os listeners para evitar vazamento de mem�ria
      mainContent.removeEventListener('touchstart', handleTouchStart);
      mainContent.removeEventListener('touchmove', handleTouchMove);
      mainContent.removeEventListener('touchend', handleTouchEnd);
    };
      }
		
		console.log("handleTouchEnd")
		const d = pullDistance.current;
		console.log("puling dist",{d})
      if (pullDistance.current > 100 && mainContent.scrollTop === 0 && !isRefreshing) {
        handleRefresh();  // S� dispara o refresh se o usu�rio puxar mais de 100px e soltar
      } else {
        setPullingDistance(0);  // Reseta o movimento visual se o usu�rio devolver o scroll
      }
    };

    // Adiciona os listeners para os eventos de toque
	if (canRefresh){
	console.log("AdicionaOsHandles", {canRefresh})
    mainContent.addEventListener('touchstart', handleTouchStart);
    mainContent.addEventListener('touchmove', handleTouchMove);
    mainContent.addEventListener('touchend', handleTouchEnd);
	}
	else {console.log("nao adicionando ja q ta false")}
    return () => {
	  console.log("RemoveOsHandlers")
      // Remove os listeners para evitar vazamento de mem�ria
      mainContent.removeEventListener('touchstart', handleTouchStart);
      mainContent.removeEventListener('touchmove', handleTouchMove);
      mainContent.removeEventListener('touchend', handleTouchEnd);
    };
  }, [isRefreshing,step,canRefresh]);

  // Fun��o para renovar URLs de v�deos
  const renewVideoUrls = async (id, fileKey) => {
    try {
      const response = await axios.post('/VideoServe/RenewUrl', {
        id: id,
        fileKey: fileKey,
      });
      if (response.data) {
        return {
          url: response.data.url,
          url_thumbnail: response.data.url_thumbnail,
        };
      }
    } catch (error) {
      console.error('Erro ao renovar as URLs', error);
    }
    return null;
  };

  // Fun��o original: renova URLs de thumbnails
  const renewThumbnailUrl = async (videoId, fileKey) => {
    try {
      const response = await axios.post('/VideoServe/RenewUrlThumb', {
        id: videoId,
        fileKey: fileKey,
      });
      if (response.data && response.data.url) {
        return response.data.url;
      }
    } catch (error) {
      console.error('Erro ao renovar a URL da thumbnail', error);
    }
    return null;
  };

  const onCancelCurrent = async () => {
    setCurrentVideo(null);
    setIsPopupOpen(false); // Fechar o popup
  };

  const handleRemoveSportCenter = async (center, index) => {
    try {
      await axios.post('/RemoveSportCenter', { sportCenterId: center.id });
      const updatedCenters = [...sportCenters];
      updatedCenters[index] = null;
      setSportCenters(updatedCenters);
    } catch (error) {
      console.error('Erro ao desassociar o Sport Center', error);
    }
  };

  const handleSelectCourt = (court) => {
    setSelectedCourt(court);
    setSelectedDate(null);
    setSelectedHour(null);
    sessionStorage.setItem('selectedCourt', JSON.stringify(court));
    setStep(2); // Move to the date selection step
  };

  const handleSelectDate = (date) => {
    setSelectedDate(date);
    setSelectedHour(null);
    sessionStorage.setItem('selectedDate', JSON.stringify(date));
    setStep(3); // Move to the hour selection step
  };

  const handleHourSelect = async (hour) => {
    setSelectedHour(hour);
    sessionStorage.setItem('selectedHour', JSON.stringify(hour));
    setStep(4); // All filters selected, videos should be loaded now

    if (selectedCourt && selectedSportCenter && selectedDate && hour) {
      try {
        const response = await axios.get(`/VideoServe/GetVideosByCourtAndDateTimeNew`, {
          params: {
            CourtId: selectedCourt.id,
            SportCenterId: selectedSportCenter.id,
            date: `${selectedDate.getFullYear()}-${("0" + (selectedDate.getMonth() + 1)).slice(-2)}-${("0" + selectedDate.getDate()).slice(-2)}`,
            hora: hour.hour,
          }
        });

        if (response.data && Array.isArray(response.data)) {
          setSections(response.data);
        } else {
          console.error('Unexpected data format:', response.data);
        }
      } catch (error) {
        console.error('Erro ao carregar os v�deos', error);
      }
    }
    setIsLoaded(true);
    setIsRefreshing(false);  // conclui o refresh
  };

  const formattimestamp = (datetime) => {
    const date = new Date(datetime);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };
   
   
   const handleUpdate = async() => {
    console.log("Update Called", {step}); 
	
    if (step === 4) {
      await handleHourSelect(selectedHour);
    } else {
      setUpdateKey(generateUniqueKey());
    }
  };

  const handleVideoSelect = async (video) => {
    if (video.urlExpired) {
      const renewedUrls = await renewVideoUrls(video.id, video.file_key);
      if (renewedUrls) {
        video.url = renewedUrls.url;
        video.urlExpired = false;
      } else {
        console.error('N�o foi poss�vel renovar as URLs');
        return;
      }
    }
    setCurrentVideo(video);
    setIsPopupOpen(true); // Abrir o popup    
  };

  const handleBack = () => {
    console.log("Back button clicked. Current step:", step);
    if (step === 4) {
      setSelectedHour(null);
      sessionStorage.removeItem('selectedHour');
    } else if (step === 3) {
      setSelectedDate(null);
      sessionStorage.removeItem('selectedDate');
    } else if (step === 2) {
      setSelectedCourt(null);
      sessionStorage.removeItem('selectedCourt');
    } else if (step === 1) {
      setSelectedSportCenter(null);
      sessionStorage.removeItem('selectedSportCenter');
    }

    if (step > 0) {
      setStep(step - 1); // Move back one step
    }
  };

  const renderThumbnail = (video) => {
    if (video.urlExpiredThumb && !fetchingThumbnails.current.has(video.id)) {
      fetchingThumbnails.current.add(video.id);
      renewThumbnailUrl(video.id, video.thumbnail_file_key);
    }
    return video.url_thumbnail || ImageThumb;
  };
  
  const handleEraseAll = () => {
	  console.log("handle Erase All")                                       
              
              console.log("Back to SportCenter. Step:", step);      	  
  }

  const handleSwiperInit = (swiper) => {
    swiper.update(); // For�a o Swiper a atualizar ap�s o primeiro render
  };

  function generateUniqueKey() {
    return `${Date.now()}-${Math.floor(Math.random() * 10000)}`;
  }

  return (
    <React.Fragment>         
	 {pullingDistance > 5 && (  // Condiciona a visibilidade do refresh-indicator
    <div 
      className={`refresh-indicator ${isRefreshing ? 'rotating' : ''}`} 
      style={{ transform: `rotate(${pullingDistance * 1.5}deg)` }}
    >
      <FontAwesomeIcon icon={faSyncAlt} />
    </div>
     )}
 
	
  <div 
  className={`videos-page ${pullingDistance === 0 ? '' : 'no-animation'}`}
  ref={mainContentRef}
  style={{
    transform: `translateY(${pullingDistance}px)`,  // Ainda controlado via JS
  }}
>    
      <div className="filters-container">
        {!selectedSportCenter ? (
          <SportCentersList
            sportCenters={sportCenters}
            onAdd={() => setShowPopup(true)}
            onRemove={handleRemoveSportCenter}
            onSelect={(sportCenter) => {
              setSelectedSportCenter(sportCenter);
              sessionStorage.setItem('selectedSportCenter', JSON.stringify(sportCenter));
              setStep(1); // Move to court selection step
            }}
          />
        ) : (
          <SelectedDetails
            selectedSportCenter={selectedSportCenter}
            selectedCourt={selectedCourt}
            selectedDate={selectedDate}
            selectedHour={selectedHour}
			isNative={isNative}
			isMobile={isMobile}
            onBackToSportCenter={() =>{
			  setSelectedHour(null);
			  setSelectedDate(null);
			  setSelectedCourt(null);
			  setSelectedSportCenter(null);
              sessionStorage.removeItem('selectedSportCenter');
              sessionStorage.removeItem('selectedCourt');
              sessionStorage.removeItem('selectedDate');
              sessionStorage.removeItem('selectedHour');
			  console.log("Back to SC. Step:", step);
              setStep(0);				
			}}
            onBackToCourt={() => {
              setSelectedCourt(null);
              setSelectedDate(null);
              setSelectedHour(null);
              sessionStorage.removeItem('selectedCourt');
              sessionStorage.removeItem('selectedDate');
              sessionStorage.removeItem('selectedHour');
              setStep(1);
              console.log("Back to Court. Step:", step);
            }}
            onBackToDate={() => {
              setSelectedDate(null);
              setSelectedHour(null);
              setSections([]);
              sessionStorage.removeItem('selectedDate');
              sessionStorage.removeItem('selectedHour');
              setStep(2);
              console.log("Back to Date. Step:", step);
            }}
            onBackToHour={() => {
              setSelectedHour(null);
              setSections([]);
              sessionStorage.removeItem('selectedHour');
              setStep(3);
              console.log("Back to Hour. Step:", step);
            }}
            OnhandleBack={handleBack}
            OnhandleUpdate={handleUpdate}
            stage={step}
			isMobile={isMobile}
          />
        )}

        {!selectedCourt && selectedSportCenter && (
          <SelectCourt sportCenter={selectedSportCenter} onSelectCourt={handleSelectCourt} updateKey={updateKey} />
        )}

        {selectedCourt && !selectedDate && (
          <CourtDetailsPage court={selectedCourt} onDateSelect={handleSelectDate} updateKey={updateKey} />
        )}

        {selectedDate && !selectedHour && (
          <DateDetailsPage court={selectedCourt} date={selectedDate} onHourSelect={handleHourSelect} updateKey={updateKey} />
        )}
      </div>

      {selectedHour ? (
        sections?.length > 0 ? (
          <>
            {currentVideo && isPopupOpen && (
              <div className="video-popup">
                <VideoPlayer
                  videoId={currentVideo.id}
                  videoUrl={currentVideo.url}
				  thumbsrc={currentVideo.url_thumbnail}
                  videoRef={videoRef}
                  videoFileKey={currentVideo.file_key}
                  cancelCurrent={onCancelCurrent}
                  setIsPopupOpen={setIsPopupOpen}
				  isNative={isNative}
                />
              </div>
            )}

            <div className="main-content">
              {sections.map((section) => (
                <div key={section.sectionIdentifier}>
				 
				  

                   <h2 className="section-header">
					<AiOutlineClockCircle size={20} />
					{formattimestamp(section.timestamp)}
					</h2>
				  
                  <Swiper
                    {...swiperSettings}
                    onInit={handleSwiperInit}
                    modules={[Navigation, Pagination]}
                  >
                    {section.videos.map((video) => (
                      <SwiperSlide key={video.id}>
                        <img
                          src={video.videocomplete ? renderThumbnail(video) : ImageNoVideo}
                          id={`thumb${video.id}`}
                          alt="Video Thumbnail"
                          className={`thumbnail ${currentVideo?.id === video.id ? 'selected-video' : ''}`}
                          onClick={video.videocomplete ? () => handleVideoSelect(video) : undefined}
                        />
                      </SwiperSlide>
                    ))}
                    <div className="swiper-pagination"></div>
                  </Swiper>
                </div>
              ))}
            </div>
          </>
        ) : (
          isLoaded ? <div>No videos available for this selection.</div> : null
        )
      ) : null}      
    </div>
	</React.Fragment>
  );
};

export default VideosPage;
