import React, { useState, useEffect } from 'react';
import axios from '../../axiosConfig';
import './DateDetailsPage.css'; 

const DateDetailsPage = ({ court, date, onHourSelect , updateKey   }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hours, setHours] = useState([]);
  const [selectedHour, setSelectedHour] = useState('');

useEffect(() => {
	 console.log("LOaaaaaaaaaaGOUasdasds");
  const fetchAvailableHours = async () => {
    try {
      console.log("LOGOU");
      console.log("LOGOU", { date });

      const day = ("0" + date.getDate()).slice(-2); // Adiciona o zero à esquerda se necessário
      const month = ("0" + (date.getMonth() + 1)).slice(-2); // Mês é zero-indexado
      const year = date.getFullYear();

      const formattedDate = `${year}-${month}-${day}`; // Formato YYYY-MM-DD

      const response = await axios.get(`/Courts/CourtHoursAvaliableByDate?Courtid=${court.id}&date=${formattedDate}`);
      setHours(response.data);
    } catch (error) {
      console.error('Erro ao carregar os horários disponíveis', error);
    } finally {
      setIsLoaded(true);
    }
  };
  console.log("LOGOUasdasds");
  fetchAvailableHours();
}, [date, court.id, updateKey ]);

  const handleHourSelect = (hour) => {
    if (hour) {
      const hourObj = typeof hour === 'object' ? hour : { hour }; // Ensure hour is an object
      setSelectedHour(hourObj.hour);
      onHourSelect(hourObj); // Pass the hour object correctly
    } else {
      console.error('Invalid hour selection:', hour);
    }
  };

  const formatHour = (hour) => {
    return `${hour.toString().padStart(2, '0')}:00`;
  };

  return (
    <div className="hours-container">
      {isLoaded && hours.length !== 0 && (        
          <h3>Select the hour of the day!</h3>        
      )}

      <div className="hours-grid">
        {hours.length === 0 ? (
          isLoaded && <h3>No available hours found for the selected date.</h3>
        ) : (
          hours.map((hour) => (
            <div
              key={hour}
              className={`hour-box${selectedHour === hour ? ' selected' : ''}`}
              onClick={() => handleHourSelect(hour)}
            >
              {formatHour(hour)}
            </div>
          ))
        )}
      </div>
    </div>    
  );
};

export default DateDetailsPage;
