// src/pages/ProfilePage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NoUserFoto from '../images/150.png'; // Foto padrão se não estiver logado

const ProfilePage = ({ user, onLogout }) => {
  const navigate = useNavigate(); // Hook para navegação

  // Redireciona para a página principal se o user for null
  useEffect(() => {
    if (!user) {
      navigate('/'); // Redireciona para a página inicial
    }
  }, [user, navigate]); // Dependências: redireciona se o 'user' mudar

  const handleLogout = () => {
    onLogout(); // Chama a função de logout
    navigate('/'); // Redireciona para a página principal
  };

  if (!user) {
    return null; // Retorna null enquanto redireciona, para evitar renderização desnecessária
  }

  return (
    <div className="profile-page">
      <h2>Profile</h2>
      <img src={user.avatarUrl || NoUserFoto} alt="User Avatar" className="user-avatar" />
      <p>Name: {user.name}</p>
      <button onClick={handleLogout}>Logoff</button> {/* Usa handleLogout para executar logout e redirecionar */}
    </div>
  );
};

export default ProfilePage;
